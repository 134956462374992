import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Table = styled.table`
  & span {
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 1.5em;
    line-height: 1;
  }
`;
const OpenTypeFeatures = ({ fontFamily }) => (
  <Table fontFamily={fontFamily}>
    <thead>
      <tr>
        <th align="left">Feature code</th>
        <th align="center">Result</th>
        <th align="center">Default</th>
        <th align="left">Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td align="left">
          <code>ss04 or cv03</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss04'" }}>g</span>
        </td>
        <td align="center">
          <span>g</span>
        </td>
        <td align="left">Single story lower case g</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss05 or cv04</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss05'" }}>l</span>
        </td>
        <td align="center">
          <span>l</span>
        </td>
        <td align="left">Lower case l without a tail.</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss06 or cv05</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss06'" }}>a</span>
        </td>
        <td align="center">
          <span>a</span>
        </td>
        <td align="left">Double story lower case a with a tail</td>
      </tr>
      <tr>
        <td align="left">
          <code>ss02</code>
        </td>
        <td align="center">
          <span style={{ fontFeatureSettings: "'ss02'" }}>a g l</span>
        </td>
        <td align="center">
          <span>a g l</span>
        </td>
        <td align="left">-</td>
      </tr>
    </tbody>
  </Table>
);

OpenTypeFeatures.propTypes = {
  fontFamily: PropTypes.string.isRequired,
};

export default OpenTypeFeatures;
